<div class="toolbar w-full px-gutter" fxLayout="row" fxLayoutAlign="start center" vexContainer *transloco="let tool_bar; read: 'toolbar'">
  <button (click)="openSidenav()" [fxHide]="!mobileQuery" mat-icon-button type="button">
    <mat-icon [icIcon]="icMenu"></mat-icon>
  </button>

  <a *ngIf="isVerticalLayout$ | async"
     [routerLink]="['/']"
     class="ltr:mr-4 rtl:ml-4 block"
     fxLayout="row"
     fxLayoutAlign="start center">
    <img class="w-8 select-none" src="assets/img/covid-logo.png">
    <h1 [fxHide]="mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">{{tool_bar('site_title')}}</h1>
  </a>

  <span fxFlex></span>

  <div class="-mx-1 flex items-center" >
    <div class="px-1">
      <button mat-button type="button" (click)="openFeedbackForm()" color="primary">{{tool_bar('feedback')}}</button>
    </div>

    <div class="px-1">
      <button [matMenuTriggerFor]="languageMenu" mat-icon-button type="button">
        <mat-icon [icIcon]="emojioneActive"></mat-icon>
      </button>
    </div>

    <mat-menu #languageMenu="matMenu" overlapTrigger="false" xPosition="before" yPosition="below">
      <button mat-menu-item (click)="changeLanguage(emojioneUK,'en')">
        <mat-icon [icIcon]="emojioneUK"></mat-icon>
        <span>English</span>
      </button>

      <button mat-menu-item (click)="changeLanguage(emojionePT,'pt')">
        <mat-icon [icIcon]="emojionePT"></mat-icon>
        <span>Portuguese</span>
      </button>

      <button mat-menu-item (click)="changeLanguage(emojioneAR,'ar')">
        <mat-icon [icIcon]="emojioneAR"></mat-icon>
        <span>Arabic</span>
      </button>

      <button mat-menu-item (click)="changeLanguage(emojioneFR,'fr')">
        <mat-icon [icIcon]="emojioneFR"></mat-icon>
        <span>French</span>
      </button>
    </mat-menu>
  </div>
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
                [fxHide]="mobileQuery"></vex-navigation>

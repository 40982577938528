import { Component, ElementRef, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import icBookmarks from '@iconify/icons-ic/twotone-bookmarks';
import emojioneUS from '@iconify/icons-emojione/flag-for-flag-united-states';
import emojioneDE from '@iconify/icons-emojione/flag-for-flag-germany';
import emojioneAR from '@iconify/icons-emojione/flag-for-flag-united-arab-emirates';
import emojionePT from '@iconify/icons-emojione/flag-for-flag-portugal';
import emojioneUK from '@iconify/icons-emojione/flag-for-flag-united-kingdom';
import emojioneFR from '@iconify/icons-emojione/flag-for-flag-france';
import icMenu from '@iconify/icons-ic/twotone-menu';
import { ConfigService } from '../../services/config.service';
import { map } from 'rxjs/operators';
import icPersonAdd from '@iconify/icons-ic/twotone-person-add';
import icAssignmentTurnedIn from '@iconify/icons-ic/twotone-assignment-turned-in';
import icBallot from '@iconify/icons-ic/twotone-ballot';
import icDescription from '@iconify/icons-ic/twotone-description';
import icAssignment from '@iconify/icons-ic/twotone-assignment';
import icReceipt from '@iconify/icons-ic/twotone-receipt';
import icDoneAll from '@iconify/icons-ic/twotone-done-all';
import { NavigationService } from '../../services/navigation.service';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import { PopoverService } from '../../components/popover/popover.service';
import { MegaMenuComponent } from '../../components/mega-menu/mega-menu.component';
import {MatDialog} from '@angular/material/dialog';
import icSearch from '@iconify/icons-ic/twotone-search';
import { TranslocoService } from '@ngneat/transloco';
import { FeedbackDialogComponent } from '../../../app/shared/feedback-dialog/feedback-dialog.component';

@Component({
  selector: 'vex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit {

  @Input() mobileQuery: boolean;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;

  navigationItems = this.navigationService.items;

  isHorizontalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'horizontal'));
  isVerticalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  isNavbarInToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'in-toolbar'));
  isNavbarBelowToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'below-toolbar'));


  icSearch = icSearch;
  icBookmarks = icBookmarks;
  emojioneUS = emojioneUS;
  emojioneActive = emojioneUK;
  emojioneDE = emojioneDE;
  emojioneUK = emojioneUK;
  emojioneFR = emojioneFR;
  emojionePT = emojionePT;
  emojioneAR = emojioneAR;
  icMenu = icMenu;
  icPersonAdd = icPersonAdd;
  icAssignmentTurnedIn = icAssignmentTurnedIn;
  icBallot = icBallot;
  icDescription = icDescription;
  icAssignment = icAssignment;
  icReceipt = icReceipt;
  icDoneAll = icDoneAll;
  icArrowDropDown = icArrowDropDown;

  constructor(private layoutService: LayoutService,
              private translocoService: TranslocoService,
              private configService: ConfigService,
              private navigationService: NavigationService,
              public dialog: MatDialog,
              private popoverService: PopoverService) { }

  ngOnInit() {
  }
  openSidenav() {
    this.layoutService.openSidenav();
  }


  openSearch() {
    this.layoutService.openSearch();
  }

  openFeedbackForm(): void {
    const dialogRef = this.dialog.open(FeedbackDialogComponent, {
      panelClass: ['md:w-3/5', 'w-full', 'feedback-dialog']
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }


  changeLanguage(language,symbol){
    this.emojioneActive = language;
    this.translocoService.setActiveLang(symbol);
  }
}

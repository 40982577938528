import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';

const routes: Routes = [
  {
    path: '',
    component: CustomLayoutComponent,
    children: [
      {
        path: 'overview',
        redirectTo: '/'
      },
      {
        path: '',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'kenya',
        loadChildren: () => import('./pages/kenya/kenya.module').then(m => m.KenyaModule),
      },
      {
        path: 'morocco',
        loadChildren: () => import('./pages/morocco/morocco.module').then(m => m.MoroccoModule),
      },
      {
        path: 'mozambique',
        loadChildren: () => import('./pages/mozambique/mozambique.module').then(m => m.MozambiqueModule),
      },
      {
        path: 'cotedivoire',
        loadChildren: () => import('./pages/cotedivoire/cotedivoire.module').then(m => m.CotedivoireModule),
      },
      {
        path: 'drc',
        loadChildren: () => import('./pages/drc/drc.module').then(m => m.DrcModule),
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
